import './monthlyForm.scss';
import './commonInspectionForm.scss';

import {format} from 'date-fns';
import * as React from 'react';
import {connect, DispatchProp} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {toast} from 'react-toastify';
import * as _ from 'lodash';

import {Col, Row} from 'react-bootstrap';
import {SIGNATURE_ERROR} from '../../constants/generals';
import {IHistory} from '../../interfaces';
import { getCommonFormQuestions, getCommonInspectionFormAnswers, saveCommonFormAnswers, uploadImages, getPriorFormDetails } from '../../services/formService';
import {parseJwt} from '../../services/loginService';
import {Async} from '../reusableComponents/Async';
import {BasePage} from '../reusableComponents/BasePage';
import {CustomSignaturePad} from '../reusableComponents/CustomSignaturePad';
import {ErrorPage} from '../reusableComponents/ErrorPage';
import {Form} from '../reusableComponents/FormComponents/Form';
import {FormFooter} from '../reusableComponents/FormComponents/FormFooter';
import {ImageUpload} from '../reusableComponents/ImageUpload';
import {FileUpload} from '../reusableComponents/FileUpload';
import {Loader} from '../reusableComponents/Loader';
import {AdvancedQuestRenderer} from '../reusableComponents/FormComponents/AdvancedQuestionsRenderer';
import {calculateActualTitleDate, calculateCurrentWeekOfMonth, formatDate, processDayofWeek, processMonthofYear} from '../../utils/generalUtils';
import { HOUSEKEEPINGSCORES } from '../../constants/generals';
import { getUsersByPlantAndLevel } from '../../services/userService';
import { RRFInput } from '../reusableComponents/FormComponents/RRFInput';
import moment from 'moment'; 

//export interface ICommonInspectionFormProps extends DispatchProp<any> {
export interface ICommonInspectionFormProps {
    userName: string;
    userUID: string; 
    history?: IHistory;
    userDetails?: any;
}

export interface ICommonInspectionFormState {
    questionsList: Array<any>;
    permanentToggle: { show: boolean; isPermanent: string; };
    uploadfilename : Array<any>;
    isLoading: boolean;
    housekeepingNotes: Array<any>;
    priorHousekeepingDetails: any;
    weekDayNameForFilter: any;
    dayofWeek: any;
    yearRangeForFilter: any;
    monthofYear: any;
}

export class CommonInspectionFormImpl extends React.Component<ICommonInspectionFormProps, ICommonInspectionFormState> {
    static defaultProps: ICommonInspectionFormProps;
    imagesList: Array<any> = [];
    fileList: Array<any> = [];
    refs: {
        signatureInput: CustomSignaturePad;
        images: FileUpload;
        fileExt: FileUpload;
    };

    basicParams = JSON.parse(sessionStorage.getItem('inspectionDetails') || '{}');
    questionCount = 0;
    ObjectiveTypeQuestionWithoutRange: boolean = false; 
    repairCompleteMinDateWithRange: any;
    repairCompleteMinDateWithoutRange: any; 
    repairMaxDate: any;
    infoRows = [
        'Use this form to document operational checks of the cathodic protection system rectifier every month.',
        'If any significant variance is observed, please report this to the Environmental'
        + ' Manager so that any necessary repairs or adjustments can be made.',
        'This report will be kept in the corporate office for at least 5 years.',
        'Rectifier Manufacturer: Universal Rectifiers, Inc.',
        'Rectifier Model: Single Phase E5',
        'Rectifier Serial Number: 104993',
        'Rated DC Output: 20 volts; 5 amps',
    ];

    constructor(props: ICommonInspectionFormProps) {
        super(props);
        this.state = {
            permanentToggle: {show: true, isPermanent: 'Yes'},
            questionsList: [],
            uploadfilename: [],
            isLoading : false,
            housekeepingNotes: [], priorHousekeepingDetails:[], weekDayNameForFilter: '', dayofWeek: '', yearRangeForFilter: '', monthofYear: '',
        };
    }

    get value() {
        return this.refs.images.value;
    }

    promise = async () => {
        const {selectedInspectionId, FormId, ParentId, date} = this.basicParams;
        if (selectedInspectionId) {
            await getCommonInspectionFormAnswers(FormId, selectedInspectionId,'Inspection', date).then(res => {
                if (res) {
                    this.imagesList = res.ImageUrls;
                    this.setState({
                        questionsList: res.questionAndAnswer
                    });
                }
            });
        } else {
            await getCommonFormQuestions(FormId, '3', 'Inspection', date).then(res => {
                this.setState({
                    questionsList: res
                });
            });
            if(ParentId === 'Daily-Weekly Check (All)80' || ParentId === 'Daily-Weekly Check (additional)80' || ParentId === 'Yearly Check80') {
                let questionsList = JSON.parse(JSON.stringify(this.state.questionsList)); 
                let isWeeklyQues = false;
                for(var key in questionsList) { 
                    let question = questionsList[key]; 
                    if(question.questionType==='ObjectiveType' ) {
                        if(question.choices.length>1) 
                            questionsList[key].answer = ParentId === 'Yearly Check80'?'~~':'~~~~~~'; 
                        else { 
                            questionsList[key].answer = '';
                            isWeeklyQues = true;
                        }
                    }
                    if(question.questionType==='Comments' || question.questionType==='Date') {
                        if(!isWeeklyQues) { 
                            questionsList[key].answer = ParentId === 'Yearly Check80'?'~~':'~~~~~~'; 
                        } else {
                            questionsList[key].answer = '';
                            if(question.questionType==='Date')
                                isWeeklyQues = false;
                        }
                    }
                } 
                this.setState({
                    questionsList
                }); 
            } 
        } 
        if(ParentId === 'Housekeeping Plant80' || ParentId === 'Housekeeping Shop80') {
            let priorHousekeepingDetails = await getPriorFormDetails(this.basicParams.plantInfo.id, FormId, format(new Date(this.basicParams.date.replace(/-/g, '/')), 'yyyy-MM-dd'));
            this.setState({
                priorHousekeepingDetails
            });  
            this.calcHousekeepingNotes(); 
            //let l3users = await getUsersByPlantAndLevel('L3', this.basicParams.plantInfo.id); #HOLD 
        }
        if(ParentId === 'Housekeeping Plant80' || ParentId === 'Housekeeping Shop80' || ParentId === 'Daily-Weekly Check (All)80' || ParentId === 'Daily-Weekly Check (additional)80' || ParentId === 'Monthly Check (All)80' || ParentId === 'Yearly Check80') {
            let questionText = '';
            switch(ParentId) {
                case 'Housekeeping Plant80':
                    questionText = 'Plant Leadperson'; break;
                case 'Housekeeping Shop80':
                    questionText = 'Shop Leadperson'; break;
                case 'Daily-Weekly Check (All)80': case 'Daily-Weekly Check (additional)80': case 'Yearly Check80': case 'Monthly Check (All)80':
                    questionText = 'Plant Manager sign off'; break; 
            }
            const quesIndex = _.findIndex(this.state.questionsList, {question: questionText});
            let leadQues = this.state.questionsList[quesIndex]; 
            if(leadQues && !leadQues.answer) {
                leadQues['answer'] = `${this.props.userDetails.First_Name} ${this.props.userDetails.Last_Name}`;
                this.onDataUpdate(leadQues);
            }
        } 
        if(ParentId === 'Daily-Weekly Check (All)80'  || ParentId === 'Daily-Weekly Check (additional)80') {
            let dayofWeek = processDayofWeek(this.basicParams.date); 
            this.setState({dayofWeek, weekDayNameForFilter: dayofWeek.weekDayName})
        }
        if(ParentId === 'Yearly Check80') {
            let monthofYear = processMonthofYear(this.basicParams.date);  
            this.setState({monthofYear, yearRangeForFilter: monthofYear.monthofYear})
        }

    }

    pickSelectedObjProps = (object: any, allowedProps: Array<string>) => {
        return _.pick(object, allowedProps);
    }

    formatFinalAnswer = (answers: Array<any>) => {
        const finalAnswerSet: Array<any> = [];
        _.forEach(answers, (answer) => {
            const fieldToPick = ['answer', 'questionId', 'isFlagged'];
            if (answer.subQuestions && answer.subQuestions.length > 0) {
                fieldToPick.push('subQuestions');
            }

            if (answer.questionType === 'Title') {
                answer.answer = null;
            }
            const formattedAnswer = this.pickSelectedObjProps(answer, fieldToPick);
            if (formattedAnswer.subQuestions && formattedAnswer.subQuestions.length > 0) {
                formattedAnswer.subQuestions = this.formatFinalAnswer(formattedAnswer.subQuestions);
            }
            finalAnswerSet.push(formattedAnswer);
        });
        return finalAnswerSet;
    }

    refineImageDataToSend = (currentImagesList: Array<any>) => {
        const imagesListToSend: Array<any> = [];
        _.forEach(currentImagesList, (image) => {
            if (typeof image === 'object') {
                imagesListToSend.push(image);
            } else {
                const imageIndex = _.findIndex(this.imagesList, {imageURL: image});
                if (imageIndex > -1) {
                    imagesListToSend.push(this.imagesList[imageIndex]);
                }
            }
        });
        return imagesListToSend;
    }

    handleFormSubmit = async () => { 
        
        if ((this.basicParams.isDeclarationRequired) && (!this.refs.signatureInput || !this.refs.signatureInput.signature)) {
            toast.error(SIGNATURE_ERROR, {
                position: toast.POSITION.TOP_RIGHT,
                className: 'failed-toast'
            });
            return;
        }
        await this.setState({isLoading:true});
        const currentImagesList = await uploadImages(this.refs.images.value, 'images');
        let signatureURL: any = null;
        if ((this.basicParams.isDeclarationRequired)) {
            signatureURL = await uploadImages([this.refs.signatureInput.signature], 'signatures');
        }

        let answersToSend = this.formatFinalAnswer(_.cloneDeep(this.state.questionsList));

        const data = {
            userId: this.props.userUID,
            plantId: this.basicParams.plantInfo.id, 
            submittedDate: formatDate(format(new Date(), 'yyyy-MM-dd'), 'MM-dd-yyyy', true),
            inspectionDate: formatDate(format(new Date(this.basicParams.date.replace(/-/g, '/')), 'yyyy-MM-dd'), 'MM-dd-yyyy', true),
            formId: this.basicParams.FormId,
            answers: answersToSend,
            time: format(new Date(), 'hh:mm a'),
            inspectionId: this.basicParams.selectedInspectionId, 
            imageUrls: (currentImagesList),
            attachmentfilename: this.state.uploadfilename,
            signatureUrls: signatureURL,
            orgId: this.basicParams.plantInfo.orgId,
            permitType: this.basicParams.permitType,
            facilityType: this.basicParams.plantInfo.facilityType,
            pointSource: this.basicParams.selectedPS.PsId || null,
            isPermanent: this.state.permanentToggle.isPermanent,
            inspectionType: this.basicParams.InspectionType,
            frequency: this.basicParams.frequency
        }; 
        return saveCommonFormAnswers(data, this.basicParams.selectedInspectionId, this.props.history);
    }

    getQuestionCount = (questData: any) => { 
        if(questData && questData.question) {
            switch(this.basicParams.ParentId) {
                case 'Housekeeping Plant80': case 'Housekeeping Shop80':
                    return questData.questionType !== 'Title' && questData.questionType !== 'Subtitle' && questData.questionType !== 'Paragraph' && questData.question !== 'Plant Leadperson' && questData.question !== 'Shop Number - Name' && questData.question !== 'Shop Leadperson' && questData.questionType !== 'BlockScore' && questData.questionType !== 'Comments'?this.questionCount += 1 : null;
                case 'Daily-Weekly Check (All)80': case 'Daily-Weekly Check (additional)80':  case 'Yearly Check80': 
                    return questData.questionType !== 'Title' && questData.questionType !== 'Subtitle' && questData.questionType !== 'Paragraph' && questData.questionType === 'ObjectiveType'?this.questionCount += 1 : null;
                case 'Monthly Check (All)80':
                    return questData.questionType !== 'Title' && questData.questionType !== 'Subtitle' && questData.questionType !== 'Paragraph' && questData.questionType === 'Date' && questData.question !== 'Repair Complete Date'? this.questionCount += 1 : null;
                default:
                    return questData.questionType !== 'Title' && questData.questionType !== 'Subtitle' && questData.questionType !== 'Paragraph'?this.questionCount += 1 : null; 
            }
        }
        return null; 
    }

    onDataUpdate = (quest: any) => {
        let parentId = this.basicParams.parentId;
        const questionsList = JSON.parse(JSON.stringify(this.state.questionsList));
        const indexOfQuest = _.findIndex(this.state.questionsList, {questionId: quest.questionId});
        if (indexOfQuest > -1) {
            questionsList[indexOfQuest] = quest;
            if( (parentId === 'Daily-Weekly Check (All)80' || parentId === 'Daily-Weekly Check (additional)80' || parentId === 'Yearly Check80') && (quest.questionType === 'Comments' || quest.questionType === 'Date') ) {  
                let answers = quest.answer.split('~');
                let answerForIndex: any = '';
                if(parentId === 'Yearly Check80') {
                    let months = ['6 Month', '12 Month'];
                    answerForIndex = months.indexOf(this.state.yearRangeForFilter);
                } else {
                    let days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];
                    answerForIndex = days.indexOf(this.state.weekDayNameForFilter);
                }
                let answer = answers[answerForIndex];
                if(answer!=='') {  
                    for (let i = 0; i < questionsList.length; i++) {
                        if (questionsList[i]['questionId'] === quest.questionId) {
                            for (let j = i - 1; j >= 0; j--) {
                                if (questionsList[j]['questionType'] === 'ObjectiveType') { 
                                    let objectiveTypeQuestion = questionsList[j];
                                    let objectiveTypeAnswers = objectiveTypeQuestion.answer.split('~')
                                    if(objectiveTypeAnswers.length>1) {
                                        objectiveTypeAnswers[answerForIndex] = this.state.weekDayNameForFilter;
                                        questionsList[j].answer = objectiveTypeAnswers.join('~');
                                    } else {
                                        questionsList[j].answer = objectiveTypeQuestion.choices[0]
                                    }
                                    break;
                                }
                            }
                            break; 
                        }
                    }  
                } 
            }
        }
        if(this.basicParams.parentId && ( this.basicParams.parentId === 'Housekeeping Plant80' || this.basicParams.ParentId === 'Housekeeping Shop80')) { 
            let totalCount = 0;
            let totalScore = 0;
            let blockScore = 0;
            let housekeepingNotes: any = [];
            for(var key in questionsList){
                let question = questionsList[key]; 
                if(question['questionType'] === 'ObjectiveType' && question['question'] !== 'Plant Leadperson' && question['question'] !== 'Shop Leadperson' && question['question'] !== 'Shop Number - Name') {
                    if(question['answer']!=='N/A')
                        totalCount++;
                    let quesScore = 0;
                    if(question['answer'] && question['answer']!=='N/A') {
                        quesScore = HOUSEKEEPINGSCORES.indexOf(question['answer'])+1;
                        if(quesScore<4) {
                            housekeepingNotes.push([question['question'],question['answer'] ])
                        }
                    }
                    blockScore += quesScore; 
                }
                if(question['questionType'] === 'BlockScore') { 
                    question['answer'] = blockScore
                    questionsList[key] = question; 
                    totalScore += blockScore; 
                    blockScore = 0;
                }
                if(question['questionType'] === 'TotalScore') { 
                    questionsList[key]['answer'] = Math.round( (totalScore/(totalCount * (5) )) * 100) ; 
                }
            }
            this.setState({ housekeepingNotes }); 
        }


        this.setState({ questionsList });
    }
    calcHousekeepingNotes = () => {
        if(this.basicParams.parentId && (this.basicParams.parentId === 'Housekeeping Plant80' || this.basicParams.parentId === 'Housekeeping Shop80') ) {  
            const questionsList = JSON.parse(JSON.stringify(this.state.questionsList)); 
            let housekeepingNotes: any = [];
            for(var key in questionsList) { 
                let question = questionsList[key]; 
                if(question['questionType'] === 'ObjectiveType' && question['question'] !== 'Plant Leadperson' && question['question'] !== 'Shop Leadperson' && question['question'] !== 'Shop Number - Name') {
                    if(question['answer'] && question['answer']!=='N/A') {
                        let quesScore = HOUSEKEEPINGSCORES.indexOf(question['answer'])+1;
                        if(quesScore<4) {
                            housekeepingNotes.push([question['question'], question['answer']] )
                        }
                    }
                }
            } 
            this.setState({ housekeepingNotes });
        }
    }
    handlefileExt = async (childData) => {
        this.setState({uploadfilename: childData});
        // await this.renderContent()
        // this.setState({isLoading : false}); 
    }
    renderHousekeepingHeader() {
        const questionsList = JSON.parse(JSON.stringify(this.state.questionsList));
        const indexOfQuest = _.findIndex(this.state.questionsList, {questionType: 'TotalScore'});
        let totalScoreQues = questionsList[indexOfQuest]; 
        let {priorHousekeepingDetails} = this.state; 
        return (
            <Row className='housekeeping-header'>
                <Col sm={6} md={6} lg={6}>
                    <div className="prior-score"> Prior Scorecard Date and Score: <span className="prior-answer">{priorHousekeepingDetails && Object.keys(priorHousekeepingDetails).length >0?`${format(new Date(priorHousekeepingDetails['date']), 'MM/dd/yyyy') } & ${priorHousekeepingDetails['totalScore']}%`: `NILL`} </span></div>
                </Col>
                <Col sm={6} md={6} lg={6}>
                    <div key="housekeeping-header-total-score" className="housekeeping-header-total-score"> <span className='housekeeping-header-total-score-title'>Grand Total: </span><span className='housekeeping-header-total-score-answer'>{totalScoreQues && totalScoreQues.answer || 0} %</span></div>
                </Col>
            </Row>
        )
    }
    renderHousekeepingNotes() { 
        let housekeepingNotes = this.state.housekeepingNotes; 
        return (<>
            <div className='housekeeping-notes'>
                <div key='housekeeping-notes' className="secondary-heading">Notes:</div> {`(List all items scored =<3 )`}
                { housekeepingNotes.length>0 ?
                    <ul>
                        { housekeepingNotes.map((infoData, index) => { 
                            return <li key={index}>{`${infoData[0]} - `}<span className='housekeepingNotesScore'>{`${infoData[1]}`}</span></li>;
                        }) }
                    </ul> :
                    <div className='no-notes'>No items found</div>
                } 
            </div>
        </>)
    }
    renderLeadPerson = (quesData: any) => { 
        return (
            <div className="question">
                <div className="question-row">
                    <div className="question">
                        <div className="show-grid input-container row">
                            <div className="input-label col">
                                <label key="">{quesData && quesData.question}</label>
                            </div>
                            <div className="input-field col">
                                <div className="input-label col">
                                    <label key="">{quesData && quesData.answer}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        );
    }
    handleWeeklyDayDropdownSelection = async (data, id) => { 
        this.setState({ weekDayNameForFilter: data.value })
    }
    handleYearRangeDropdownSelection = async (data, id) => { 
        this.setState({ yearRangeForFilter: data.value })
    } 
    processExecutedQuestion(quesData) { 
        const { ParentId, date } = this.basicParams; 
        if((ParentId === 'Daily-Weekly Check (All)80' || ParentId === 'Daily-Weekly Check (additional)80' || ParentId === 'Yearly Check80') && quesData.questionType==='ObjectiveType' ) {
            this.ObjectiveTypeQuestionWithoutRange = quesData.choices.length === 1 ? true : false; 
        }
        if(ParentId === 'Monthly Check (All)80') {
            if(quesData.questionType==='Date' && quesData.question!=='Repair Complete Date') {  
                this.repairCompleteMinDateWithoutRange = quesData.answer? moment(quesData.answer).format('MM/DD/yyyy'):moment(date).startOf('month').format('MM/DD/yyyy'); 
            }
            if(quesData.questionType==='Date' && quesData.question==='Repair Complete Date') {
                //this.repairCompleteMinDateWithoutRange = moment(date).startOf('month').format('MM/DD/yyyy'); 
                this.repairCompleteMinDateWithoutRange = undefined;
                this.repairMaxDate = moment().format('MM/DD/yyyy'); 
            }
        }
        return;
    }
    renderContent = () => {
        this.questionCount = 0;  
        const {dayofWeek, weekDayNameForFilter, monthofYear, yearRangeForFilter} = this.state; 
        const { ParentId, date } = this.basicParams;
        let weeklyDayDropdown: any = [];
        let yearRangeDropdown: any = [];
         
        if(ParentId==='Daily-Weekly Check (All)80' || ParentId==='Daily-Weekly Check (additional)80'){
            let weeks = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];
            weeks.map((day, index) => { 
                if(!dayofWeek.ongoingWeek || (dayofWeek.weekdayIndex>=index) ) {
                    weeklyDayDropdown.push({'label': day, 'value': day});
                }
            }); 
            this.repairCompleteMinDateWithRange = moment(date).startOf('isoWeek').day(weekDayNameForFilter).format('MM/DD/yyyy');
            this.repairCompleteMinDateWithoutRange = moment(date).startOf('isoWeek').format('MM/DD/yyyy')
        }
        if(ParentId==='Yearly Check80') {
            let yearRange = [6, 12 ];
            yearRange.map((range, index) => { 
                if(!monthofYear.ongoingHalfYear || (monthofYear.monthIndex>=index) ) {
                    yearRangeDropdown.push({'label': range + ' Month', 'value': range});
                } 
            });  
            let date = yearRangeForFilter===6?`01-01-${monthofYear.year}`:`07-01-${monthofYear.year}`;
            this.repairCompleteMinDateWithRange = moment(date).format('MM/DD/yyyy');
            this.repairCompleteMinDateWithoutRange = moment(`01-01-${monthofYear.year}`).format('MM/DD/yyyy')
        } 
        
        return <Form
            model="forms.commonInspectionForm"
            onSubmit={this.handleFormSubmit}
            className="form inspection-form">
            {this.state.isLoading ? <Loader type="submission"/> : ''}
            {ParentId === 'WaterMonthlyUSTRect' && <div className="information">
                <h3>UST Rectifier Reading Form</h3>
                <ul>
                    {this.infoRows.map((infoData, index) => {
                        return <li key={index}>{infoData}</li>;
                    })}
                </ul>
            </div>}
            {/*  {
                <div className="information question-row">Last modified date: {format(new Date(), 'MM-dd-yyyy')}</div>
            }*/}
            <React.Fragment>
                <div className="question-row"
                     style={{borderBottom: '2px solid #a4bf43', marginBottom: '1%', width: '98%', marginLeft: '1%'}}>
                    <div className="question"> 
                        {'Is a permenant facility?'}
                    </div>
                    <div className={`buttons`}>
                        {[{label: 'Yes', value: true}, {label: 'No', value: false}].map((btn, index) => (
                            <button
                                key={index}
                                onClick={() => {
                                    const permanentToggle = this.state.permanentToggle;
                                    permanentToggle.isPermanent = btn.label;
                                    this.setState({
                                        permanentToggle
                                    });
                                }}
                                type="button"
                                className={(this.state.permanentToggle.isPermanent === btn.label) ? 'button-yes'
                                    : 'button-no'}
                            >{btn.label}</button>
                        ))}
                    </div>
                </div>
                { (ParentId === 'Daily-Weekly Check (All)80' || ParentId === 'Daily-Weekly Check (additional)80') && 
                    <div>
                        <div className='weekly-day-dropdown'>  
                            <label>Select day of the week </label>
                            <RRFInput
                                model={`.weeklyDayDropdown`}
                                type="dropdown"
                                placeholder={`Select`}
                                id={`weeklyDayDropdown`} 
                                onSelect={this.handleWeeklyDayDropdownSelection}
                                label={null}  
                                defaultValue={weekDayNameForFilter} 
                                menuItems={weeklyDayDropdown}
                            />
                        </div>
                    </div>
                }
                { (ParentId === 'Yearly Check80') && 
                    <div>
                        <div className='weekly-day-dropdown'>  
                            <label>Select year range </label>
                            <RRFInput
                                model={`.yearRangeDropdown`}
                                type="dropdown"
                                placeholder={`Select`}
                                id={`yearRangeDropdown`} 
                                onSelect={this.handleYearRangeDropdownSelection}
                                label={null}  
                                defaultValue={yearRangeForFilter} 
                                menuItems={yearRangeDropdown}
                            />
                        </div>
                    </div>
                }
            </React.Fragment>
            {(ParentId === 'Housekeeping Plant80' || ParentId === 'Housekeeping Shop80') && this.renderHousekeepingHeader() }
            {this.state.questionsList && this.state.questionsList.map((quesData, index: number) => ( 
                ((ParentId && (ParentId === 'Housekeeping Plant80' || ParentId === 'Housekeeping Shop80' || ParentId === 'Daily-Weekly Check (All)80' || ParentId === 'Daily-Weekly Check (additional)80' || ParentId === 'Monthly Check (All)80' || ParentId === 'Yearly Check80') && (quesData.question==='Plant Leadperson' || quesData.question==='Shop Leadperson' || quesData.question==='Plant Manager sign off' ) )?this.renderLeadPerson(quesData): [<AdvancedQuestRenderer
                    key={index}
                    quest={quesData}
                    localQuestNo={this.getQuestionCount(quesData)}
                    onDataUpdate={this.onDataUpdate}
                    additionalInfo={{dayofWeek, weekDayNameForFilter, monthofYear, yearRangeForFilter,
                        repairCompleteMinDate: ParentId === 'Monthly Check (All)80' ? this.repairCompleteMinDateWithoutRange : (this.ObjectiveTypeQuestionWithoutRange ? this.repairCompleteMinDateWithoutRange : this.repairCompleteMinDateWithRange), repairMaxDate: ParentId === 'Monthly Check (All)80' ? this.repairMaxDate : undefined }}
                />, this.processExecutedQuestion(quesData) ]
                )))
            }
            {
                ParentId && (ParentId === 'Housekeeping Plant80' || ParentId === 'Housekeeping Shop80') && this.renderHousekeepingNotes()
            }
            <hr className="line-break"/>
            {(this.basicParams.isDeclarationRequired) && <div style={{padding: '45px', fontSize: '18px'}}>
                I certify under penalty of law that this document and all attachments were
                prepared under my direction or supervision in accordance with a system designed to
                assure that qualified personnel properly gathered and evaluated the information
                submitted. Based on my inquiry of the person or persons who manage the system, or those
                persons directly responsible for gathering the information, the information submitted
                is, to the best of my knowledge and belief, true, accurate, and complete. I am
                aware that there are significant penalties for submitting false information,
                including the possibility of fine and imprisonment for knowing violations.
            </div>}
            <div className="image-signature-container"> 
                <FileUpload
                    ref="images"
                    multi={true}
                    imagesList={this.imagesList}
                    fileExtFunction={this.handlefileExt}
                    fileList={this.fileList}
                    label="Select Attachment:"
                />
                {(this.basicParams.isDeclarationRequired) &&
                <CustomSignaturePad ref="signatureInput" userName={this.props.userName}/>}
            </div>
            { <FormFooter redirectTo="/inspections"/> }
        </Form>;
    }

    render() {
        const {FormName, permitType, plantInfo, frequency, date, selectedPS} = this.basicParams;
        const outfall = selectedPS.PsName !== 'Select' ? ' (' + selectedPS.PsName + ')' : '';
        return <BasePage
            pageTitle={`${FormName + outfall} - ${permitType} Permit - ${plantInfo.facilityType} - ${plantInfo.plantId} - ${calculateActualTitleDate(frequency, date)}`}>
            <Async
                identifier="MonthlyForm"
                promise={this.promise}
                content={this.renderContent()}
                loader={<Loader type="async"/>}
                error={<ErrorPage/>}
            />
        </BasePage>;
    }
}

export function mapStateToProps(state: any, ownProps: any) {
    const userDetails = parseJwt(state.login.get('token')); 
    return {
        userUID: state.login.get('UniqueId'),
        userName: userDetails.First_Name, userDetails
    };
}

export const CommonInspectionForm = withRouter(connect<ICommonInspectionFormProps, any, any>(mapStateToProps)(CommonInspectionFormImpl));
